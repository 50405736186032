import React, {Component} from 'react'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import MailIcon from '@material-ui/icons/Mail'
import './sharedcss.css'
import './footer.css'

export default class Footer extends Component{
    render(){
        return(
            <div className="footer">
                <div className="social-media">
                    <a href="https://www.facebook.com/malin.granroth.illustration/" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon style={{fontSize: 30, paddingLeft: 5, paddingRight: 5}} />
                    </a>
                    <a href="http://www.instagram.com/malingranroth" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon style={{fontSize: 30, paddingLeft: 5, paddingRight: 5}} />
                    </a>
                    <a href="mailto:info@malingranroth.se">
                        <MailIcon style={{fontSize: 30, paddingLeft: 5, paddingRight: 5}} />
                    </a>
                </div>
                <p className="small-text">
                Hemsida gjord av Malin Granroth, 2020.</p>
                
            </div>
        )
    }
}