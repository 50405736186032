import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Footer from './footer'
import bokomslag from './images/ekim_omslag.jpg'
import './sharedcss.css'

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
}));

export default function Cv() {
  const classes = useStyles();
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);

  const handleExpand1Click = () => {
    setExpanded1(!expanded1);
  };

  const handleExpand2Click = () => {
    setExpanded2(!expanded2);
  };

  const handleExpand3Click = () => {
    setExpanded3(!expanded3);
  };

  return (
    <div>
        <div className="main-content" style={{paddingBottom:0}}>
            <Grid container 
                spacing={3}
                direction="row"
                justify="center"
                alignItems="stretch"
                className="center-content">                
                <Grid item xs>
                    <Paper className="grid-item">
                        <h3 style={{paddingTop: 0}}>Böcker</h3>
                        <ul>
                          <li>Skorpan & Smulan, Dotterbolaget &lt;3 Asylgruppen, 2022.</li>
                          <li>En klump i magen, Kartago förlag, 2020.  </li>
                        </ul>                 
                        <h3>Antologier</h3>
                        <ul>
                            <li>Serienördens djurbok, Doob förlag, 2024.</li>
                            <li>Arbetaren: 100 år som fri tidning, Federativs förlag, 2022.</li>
                            <li>Tecknarupproret mot kärnvapen och Nato, Galago förlag, 2022.</li>
                            <li>Serienördens coronabok, Doob förlag, 2021.</li>
                            <li>Draw the line, Doob förlag, 2018.</li>
                            <li>Ett kollektiv av röster - 100 år av systerskap, Leopard förlag, 2017.</li>
                            <li>Serienördens trädgårdsbok, Doob förlag, 2017.</li>
                            <li>Dotterbolaget Malmö 10 år, 2015.</li>
                        </ul>
                        <h3>Erhållna stipendier</h3>
                        <ul>
                          <li>Byggnads kulturstipendium, 2024.</li>
                          <li>Malmö stads kulturstipendium för konstnärlig utveckling, 2022.</li>
                          <li>Sveriges författarfond, 2021. </li>
                        </ul>
                        <h3>Nomineringar</h3>
                        <ul>
                          <li>Utställningen En klump i magen, Trelleborgs museum, årets utställning, 2022.</li>
                          <li>Arbetarrörelsens kulturpris, ABF, 2018.</li> 
                        </ul>                     
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper className="grid-item">
                        <img src={bokomslag} alt="Bokomslag, En klump i magen" className="image"/>
                        <span className="small-text">En klump i magen, serieroman utgiven av Kartago förlag, 2020.</span>
                    </Paper>
                </Grid>
            </Grid>
        </div>
        

    {/* <div className="main-content bottom">
      <Grid container
            spacing={3} 
            direction="row"
            justify="center"
            alignItems="stretch"
            className="center-content">
    <Grid item xs>
    <Card className="card-padding">
      <CardContent>
        <h3 style={{padding: 0}}>Publiceringar</h3>
        <p className="date">2013 - 2020</p>
        <Typography variant="body2" color="textSecondary" component="p">
          Jag har blivit publicerad i tidningar såsom Galago, Historiskan, Ottar, Vi Föräldrar gravid och Flamman.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>        
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded1,
          })}
          onClick={handleExpand1Click}
          aria-expanded={expanded1}
          aria-label="Läs mer"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded1} timeout="auto" unmountOnExit>
        <CardContent>
            <span className="bold-text">2020,</span> Bild & Bubbla, nr 223, Seriefrämjandet
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> Trelleborgs Allehanda, 25/5
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> Skånska Dagbladet, 5/5
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> Norra Skåne, 5/5 2020
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> Norrköpings Tidningar, 27/4
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> Lokaltidningen Malmö, vecka 11 mars 2020, Sydsvenskan
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> OTTAR, nr 1/2020, RFSU
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> Bild & Bubbla, nr 222, Seriefrämjandet
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2020,</span> Folkviljan, nr 1 2020, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Asylkalendern 2020
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Flamman, nr 27-28 2019, Tidningsföreningen Norrskensflamman
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Filterbubblan, nr 2 2019
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Kvarnby Serier 2019
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Flamman, nr 18 2019, Tidningsföreningen Norrskensflamman
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Tidningen Vision, nr 3 2019, Vision
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Mana, nr 4, Föreningen Tidskriften Mana
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2019,</span> Galago, nr 134, Ordfront Galago förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Galago, nr 133, Ordfront Galago förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Bild och Bubbla, nr 4 2018, Seriefrämjandet
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Brand, nr 3 2018, Tidningen Brand
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Tecknaren, nr 4/5 2018, Svenska Tecknare
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Dansknoter, nr 3 2018, Dansklærerforeningen
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Draw the line, Doob förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Vänstern i Malmö, nr 3, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Galago, nr 132, Ordfront Galago förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Galago, nr 131, Ordfront Galago förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Galago, nr 130, Ordfront Galago förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2018,</span> Historiskan, 1/2018, Systerskapet AB
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Mana, nr 2-3, Föreningen Tidskriften Mana
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Samhällsmagasinet RÖTT nr 4, Vänsterpartiet
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Verkligheter, nr 4, Genusföreningen Uppsala Universitet
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Vi föräldrar gravid, nr 3, Bonnier Magazines & Brands
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Serienördens trädgårdsbok, Doob förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Ett kollektiv av röster – 100år av kvinnokamp, Leopard förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Vänstern i Malmö, nr 4
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Galago, nr 127, Ordfront Galago förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Samhällsmagasinet RÖTT nr 2, Vänsterpartiet
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Kvarnby Serier 2017
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Home Made Comics
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Asylkalendern
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Det grymma svärdet, nr 26, Lystring förlag
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Gotha, nr 13, PA Magasiner
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Folkviljan, nr 5, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Folkviljan, nr 4, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Folkviljan, nr 2, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2017,</span> Folkviljan, nr 1, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2016,</span> Home Made Comics
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2016,</span>, Verkligheter, nr 3, Genusföreningen Uppsala Universitet
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2016,</span> Asylkalendern
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2015,</span> Dotterbolaget 10år
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2015,</span> Skånes Fria tidning, nr 13
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2015,</span> Folkviljan, nr 1, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2015,</span> Solidaritetskort inför 1maj, Vänsterpartiet Malmö
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2014,</span> Skånes Fria tidning, nr 42
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2014,</span> Skånes Fria tidning, nr 41
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2014,</span> Skånes Fria tidning, nr 39
            <Divider style={{marginTop: 15, marginBottom : 15}} />
            <span className="bold-text">2013,</span> Mahskara, nr 75, Malmö Högskolas studentmagasin
            <Divider style={{marginTop: 15, marginBottom : 15}} />
        </CardContent>
      </Collapse>
    </Card>
    </Grid>



    <Grid item xs>
    <Card className="card-padding">   
      <CardContent>
      <h3 style={{padding: 0}}>Utställningar</h3>
        <p className="date">2014 - 2020</p>
        <Typography variant="body2" color="textSecondary" component="p">
          Jag har deltagit och arrangerat en rad olika utställningar, både solo- och gemensamma utställningar.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>        
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded2,
          })}
          onClick={handleExpand2Click}
          aria-expanded={expanded2}
          aria-label="Läs mer"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded2} timeout="auto" unmountOnExit>
        <CardContent>
          <span className="bold-text">2020,</span> Helsingborgs stadsteater, Helsingborg <span className="italic-text">(Samlingsutställning Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Rum för Serier, Malmö <span className="italic-text">(Kvarnby Serieskolans slututställning)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Kulturnatten, Pill & Punch, Malmö <span className="italic-text">(Samlingsutställning)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Kulturnatten, Garaget, Malmö <span className="italic-text">(Dotterbolaget intar Garaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Poeten på hörnet, Malmö <span className="italic-text">(Det personliga är politiskt)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Stadsbiblioteket, Malmö <span className="italic-text">(Samlingsutställning Serieskolan)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Malmö Universitet, Malmö <span className="italic-text">(Draw the line)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Kulturhuset, Borås <span className="italic-text">(Draw the line)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> KÖKART Festival, Åland <span className="italic-text">(Samlingsutställning Femart)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Serieteket, Stockholm <span className="italic-text">(Draw the line)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Lets make love great again, Gärdet, Stockholm <span className="italic-text">(Samlingsutställning Femart)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Panora, Malmö <span className="italic-text">(Dotterbolaget brinner!)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> The Castle, Stockholm <span className="italic-text">(Femart lanseringsvernissage)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Mejeriet, Lund <span className="italic-text">(8 mars på Mejeriet)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Arlövs bibliotek och konsthall, Arlöv <span className="italic-text">(Dotterbolaget brinner!)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Oceanen, Göteborg <span className="italic-text">(Konsten att (bok)klubba)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Rum för Serier, Malmö <span className="italic-text">(Kvarnby Serieskolans slututställning)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Feministisk Festival, Malmö <span className="italic-text">(Samlingsutställning Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Kulturhuset, Borås <span className="italic-text">(Satirutställning)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Utomhusutställning på Friisgatan, Malmö <span className="italic-text">(Samlingsutställning Kvarnby Serieskola)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2016,</span> Rum för serier, Malmö (<span className="italic-text">Jaget. Laget. Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2016,</span> Feministisk Festival, Malmö <span className="italic-text">(Samlingsutställning Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2015,</span> Stadsbiblioteket, Göteborg <span className="italic-text">(Samlingsutställning Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2015,</span> Panora, Malmö <span className="italic-text">(Samlingsutställning Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2015,</span> Galleri Slätten, Malmö <span className="italic-text">(Dotterbolaget känner efter)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2015,</span> Stadsbiblioteket, Malmö <span className="italic-text">(Dotterbolaget 10år)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2014,</span> Kulturhuset, Borås <span className="italic-text">(Samlingsutställning Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2014,</span> Domkyrkoforum, Lund <span className="italic-text">(Samlingsutställning Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
        </CardContent>
      </Collapse>
    </Card>
    </Grid>



<Grid item xs>
    <Card className="card-padding">     
      <CardContent>
      <h3 style={{padding: 0}}>Uppdrag</h3>
        <p className="date">2014 - 2020</p>
        <Typography variant="body2" color="textSecondary" component="p">
          Illustrationsuppdrag såsom affischer, tryck till merchandise, omslag och liknande.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>        
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded3,
          })}
          onClick={handleExpand3Click}
          aria-expanded={expanded3}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded3} timeout="auto" unmountOnExit>
        <CardContent>
          <span className="bold-text">2020,</span> Affisch till Internationella kvinnodagen, Vänsterpartiet Falun
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Affisch till Internationella kvinnodagen, Vänsterpartiet Falun
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Affisch till Internationella kvinnodagen, Malmö, <span className="italic-text">(samlingsuppdrag Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2019,</span> Affisch till MALMÖTXT 15
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Illustration till mugg och annat tryckt material, Arbetarlitteratur
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Illustration till tryck på T-shirt, tygkasse och mugg, Vänsterpartiet Region Skåne
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Illustration till tygkasse, Vänsterpartiet Göteborg
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Illustrerat omslag till rapportserien, i tre delar, “Jämställd vård och omsorg”, Vänsterpartiet Region Skåne
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2018,</span> Gjort två affischer till Tjejjouren Skogsrå
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Illustrerat gåvobevis, Kontrapunkt, Malmö, <span className="italic-text">(samlingsuppdrag Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Illustration till tyck på T-shirt och tygkasse, Vänsterpartiet Malmö
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Affisch till 1 maj, Vänsterpartiet Lund
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Illustration till flyer Internationella kvinnodagen, Vänsterpartiet Malmö
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2017,</span> Affisch till Internationella kvinnodagen, Malmö, <span className="italic-text">(samlingsuppdrag Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2016,</span> Affisch till Internationella kvinnodagen, Malmö, <span className="italic-text">(samlingsuppdrag Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2015,</span> Solidaritetskort inför 1 maj till förmån för Ship to Gaza, Vänsterpartiet Malmö
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2015,</span> Affisch till Internationella kvinnodagen, Malmö, <span className="italic-text">(samlingsuppdrag Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2014,</span> Affisch till Internationella kvinnodagen, Malmö, <span className="italic-text">(samlingsuppdrag Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
          <span className="bold-text">2014,</span> Affisch och logotyp till Feministisk Festival, Malmö, <span className="italic-text">(samlingsuppdrag Dotterbolaget)</span>
          <Divider style={{marginTop: 15, marginBottom : 15}} />
        </CardContent>
      </Collapse>
    </Card>
    </Grid>
    </Grid> 

    </div>*/}
    <Footer />
    </div>
  );
}