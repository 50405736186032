import React, { Component } from 'react'
import { Route, NavLink, BrowserRouter } from 'react-router-dom'
import Portfolio from './portfolio'
import Kontakt from './kontakt'
import Home from './home'
import Press from './press'
import Cv from './cv'
import Kalender from './kalender'
import ScrollToTop from './scrollToTop'
import './App.css'
import './theme.css'

export default class App extends Component{
  state = {
    data: null
  }

  // componentDidMount() {
  //   this.callBackendAPI()
  //     .then(res => this.setState({ data: res.express }))
  //     .catch(err => console.log(err))
  // }
  
  // callBackendAPI = async () => {
  //   const response = await fetch('/express_backend')
  //   const body = await response.json();

  //   if (response.status !== 200) {
  //     throw Error(body.message) 
  //   }
  //   return body
  // }

  render(){  
  return (
      <BrowserRouter>
        <header className="header">
          <NavLink to="/" className="logo menu-item">Malin Granroth</NavLink>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
          <ul className="menu">
            <ScrollToTop />
            <li><NavLink to="/portfolio" className="menu-item" activeClassName="chosen">Portfolio</NavLink></li>
            <li><NavLink to="/press" className="menu-item" activeClassName="chosen">Press</NavLink></li>
            {/* <li><NavLink to="/kalender" className="menu-item" activeClassName="chosen">Kalender</NavLink></li>*/}
            <li><NavLink to="/cv" className="menu-item" activeClassName="chosen">Cv</NavLink></li>
            <li><NavLink to="/kontakt" className="menu-item" activeClassName="chosen">Kontakt & info</NavLink></li>
            {/* <li><NavLink to="/webbshop" className="menu-item" activeClassName="chosen">Webbshop</NavLink></li> */}
          </ul>
        </header>

        <Route exact path="/" component={Home} />
        <Route path="/kontakt" component={Kontakt} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/press" component={Press} />
        <Route path="/cv" component={Cv} />
        <Route path="/kalender" component={Kalender} />  
      </BrowserRouter>
    )
  }
}