import React, {Component} from 'react'
import { fadeInDown } from 'react-animations'
import styled, { keyframes } from 'styled-components'
import { NavHashLink  as Link } from 'react-router-hash-link'
import CustomButton from './CustomButton'
import './sharedcss.css'
import './home.css'

const FadeInDownAnimation = keyframes`${fadeInDown}`;
const FadeInDownDiv = styled.div`animation: 1.5s ${FadeInDownAnimation};`;

const list = [    
    "Bildpedagog och barnrättsstrateg",    
    // "Driver webbshop med mina egna produkter",
    "Undervisar inom kulturskolan",
    //"Illustrerar merchandise såsom tygpåsar, muggar, pins m.m.",
    "Workshopledare inom serieteckning och konst",
    "Illustrerar omslag, artiklar, affischer m.m.",
    "Har du en idé på ett uppdrag eller sammarbete? Kontakta mig!"
]

export default class Home extends Component{
    constructor(props){
        super(props);
        this.state={
            text:""
        }
        this.btnClick = this.btnClick.bind(this);
    }

    async componentDidMount(){
        list.forEach((name, i) => {
            setTimeout(() => {
                this.setState({text:name});
            }, i * 5000);
        })
    }

    btnClick(e){

    }

    render(){
        return (
            <div className="homepage-content">  
                <FadeInDownDiv>    
                    <h1>Frilansande illustratör<br/>
                    och serietecknare</h1>
                </FadeInDownDiv>
            
                <div className="second-header-content">
                    <h2>{this.state.text}</h2>                    
                </div>    
            
                <Link smooth to="/kontakt#contact-form" className="button-navlink">
                    <CustomButton onClick={this.btnClick} title="Kontakta mig"/>
                </Link>    
            </div> 
        )
    }
}