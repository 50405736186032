import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { events } from "./events";
import Divider from '@material-ui/core/Divider'
import Footer from './footer'
import './sharedcss.css'
import './kalender.css'

export default class Press extends Component{
    render(){
        return (    
            <div>
                <div className="main-content">
                    <Grid container 
                        spacing={3} 
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        className="center-content">
                        <Grid item xs>
                            <Paper className="grid-item"> 
                            {events.map((event) => (                                
                                <div className="calender-content" key={event.header}>
                                    <div>
                                        <h3>{event.header}</h3>
                                        <p className="date">{event.date}</p>
                                    </div>
                                    <div className="calender-info">
                                        <Grid item className="calender-image">
                                            <img src={event.src} alt={event.header} className="image"/>
                                        </Grid>
                                        <Grid item xs className="calender-text">
                                            <p>{event.text}</p>
                                            <p><span className="bold-text">Plats:</span> {event.location}</p>
                                            {event.time !== undefined &&
                                                <p>
                                                    <span className="bold-text">Tid: </span> 
                                                    {event.time}
                                                </p>
                                            }
                                            {event.link !== undefined &&
                                                <p>
                                                    <span className="bold-text">Länk: </span> 
                                                    <a href={event.link} target="_blank" rel="noopener noreferrer">Läs mer här</a>
                                                </p>
                                            }
                                            
                                        </Grid>
                                    </div>
                                    <Divider style={{marginTop: 30, marginBottom : 30}} />
                                </div>
                            ))}                          
                            </Paper>
                        </Grid>                    
                    </Grid>
                </div>
                <Footer/>
            </div>   
        )
    }
}