import React, { useState, useCallback } from 'react'
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Paper from '@material-ui/core/Paper'
import Footer from './footer'
import { photos } from "./photos"
import './sharedcss.css'


export default function Portfolio(){
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightBox = useCallback((event, {photo, index}) =>{
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);
    
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    return (
        <div>
            <div className="main-content">            
                <div className="center-content gallery-content">
                    <Paper className="grid-item">
                        <Gallery photos={photos} onClick={openLightBox} />
                            <ModalGateway>
                                {viewerIsOpen 
                                    ? (<Modal onClose={closeLightbox}>
                                            <Carousel            
                                                currentIndex={currentImage}
                                                description={currentImage.description}  
                                                views={photos.map(x => ({
                                                    ...x,
                                                    srcset: x.srcSet,
                                                    description: x.description
                                                }))}
                                            />
                                        </Modal>) 
                                    : null}
                            </ModalGateway>
                        </Paper>
                    </div>
                </div>
            <Footer/>
        </div>
    )
}
