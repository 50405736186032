import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { deepPurple } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: deepPurple
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  }
}));

export default function LoadingBar(){
    const classes = useStyles();
    return(
        <div theme={theme} className={classes.root}>
            <CircularProgress color="primary" />
        </div>
    )  
}