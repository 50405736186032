import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import DescriptionIcon from '@material-ui/icons/Description'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Footer from './footer'
import flamman1 from './images/press/flamman1.jpg'
import flamman2 from './images/press/flamman2.jpg'
import flamman3 from './images/press/flamman3.jpg'

export default class Press extends Component{
    render(){
        return (
            <div>
                <div className="main-content">
                    <Grid container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        className="center-content">
                        <Grid item xs>
                            <Paper className="grid-item">

                                <img src="http://malingranroth.se/portfolio/popula.PNG" alt="Popula, Malin Granroth" className="image"/>

                                Jag har varit med i det sverigefinska radioprogrammet Popula! Programledarna Sanna och Kalle intervjuvar mig om mitt
                                pågående serieprojekt där jag undersöker mitt finska arv och Finlands historia. Här kan ni läsa artikeln 
                                <a href="https://sverigesradio.se/avsnitt/malin-granroth-tecknar-sitt-sverigefinska-arv-for-sina-barn/" target="_blank" rel="noopener noreferrer"> "Malin Granroth tecknar sitt sverigefinska arv för sina barn" </a> 
                                och lyssna på programmet. 

                            <Divider style={{marginTop: 30, marginBottom : 30}} />

                                Aktuell med utställningen Stafetten 2.0 - Serier & seriekonst på Östergötlands museum tillsammans med Joatan Rosén. 
                                Utställningen pågår 25/2 - 11/6 2023 i Galleri Wåghals. 
                                <a href="https://ostergotlandsmuseum.se/utstaellningar/tidigare-utstaellningar/stafetten-serier-och-seriekonst/" target="_blank" rel="noopener noreferrer"> Länk till Östergörlands museums hemsida.</a> 
                            
                            <Divider style={{marginTop: 30, marginBottom : 30}} />

                            <Grid item xs>
                                    <img src="http://malingranroth.se/portfolio/skanskadagbladet.jpg" alt="Skånska Dagbladet, Malin Granroth" className="image"/>
                                </Grid>

                                <a href="https://www.skd.se/2022-09-23/graviditet-forlossning-och-kris-pa-trelleborgs-museums-vaggar" target="_blank" rel="noopener noreferrer"> Graviditet, förlossning och kris på Trelleborgs museums väggar. </a> 
                                Artikel i Skånska Dagbladet om min utställning på Trelleborgs museum. 
                           

                              <Divider style={{marginTop: 30, marginBottom : 30}} />

                                Graviditet i serieform! Jag ställer ut på Trelleborgs museum, utställningen heter En klump i magen och baseras på min bok med samma namn.
                                Utställningsperiod 24 september - 12 mars 2023. 
                                <a href="https://www.trelleborg.se/evenemang/malin-granroth-en-klump-i-magen/" target="_blank" rel="noopener noreferrer"> Här </a> kan ni läsa mer om utställningen på Trelleborgs museums hemsida. 
                           

                              <Divider style={{marginTop: 30, marginBottom : 30}} />


                              Jag är en av stipendiaterna som fått kulturstipendium för konstnärlig utveckling av Malmö stad 2022.
                              <a href="https://malmo.se/Aktuellt/Artiklar-Malmo-stad/2022-04-20-Malmo-stads-kulturstipendiater-far-dela-pa-650-000-kronor.html" target="_blank" rel="noopener noreferrer"> Här </a> 
                              kan ni läsa mer om alla kulturutövare som också tilldelats kulturstipendium av Malmö stad 2022. 
                           
                              
                              <Divider style={{marginTop: 30, marginBottom : 30}} />

                              Jag är en av många serietecknare som deltar i serieområdet på Norra Parkgatan.
                              Projektet är ett samarbete mellan Malmö Stad Fastighets- och Gatukontoret och Fastighetsägare BID Möllevången & BID Sofielund.   
                              <a href="https://malmo.se/Uppleva-och-gora/Evenemang/Serieomradet-vid-Norra-Parkgatan.html" target="_blank" rel="noopener noreferrer"> Här </a>
                              står mer om projektet på Malmö stads hemsida och 
                              <a href="https://bidmalmo.se/satter-malmo-pa-seriekartan/" target="_blank" rel="noopener noreferrer"> här </a>
                              är en artikel om öppningen av serieområdet. 
                              
                                <br /><br />
                                <Grid container
                                    spacing={0}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    className="center-content">
                                        <Grid item xs>
                                            <img src="http://malingranroth.se/portfolio/seriestaden1.jpg" alt="Serieområdet på Norra Parkgatan" className="image"/>
                                        </Grid>
                                        <Grid item xs style={{marginLeft:5}}>
                                            <img src="http://malingranroth.se/portfolio/seriestaden2.jpg" alt="Serieområdet på Norra Parkgatan" className="image" />
                                        </Grid>
                                        <Grid item xs style={{marginLeft:5}}>
                                            <img src="http://malingranroth.se/portfolio/seriestaden3.jpg" alt="Serieområdet på Norra Parkgatan" className="image"/>
                                        </Grid>
                                </Grid>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />






                              <Grid item xs>
                                    <img src="https://malingranroth.se/portfolio/kommunalarbetaren.jpg" alt="Kommunalarbetaren, Malin Granroth" className="image"/>
                                </Grid>

                              Artikel om mitt serieprojekt Arbetarhistoria publicerat i fackförbundet Kommunals medlemstidning Kommunalarbetaren. Även en av mina serier finns att läsa på deras hemsida.
                              <a href="https://ka.se/2021/12/03/malin-granroth-tecknar-arbetarrorelsens-historia/" target="_blank" rel="noopener noreferrer"> Här </a> finns artikeln och serien.

                                
                                <Divider style={{marginTop: 30, marginBottom : 30}} />

                                Jag har påbörjat ett nytt projekt där jag kommer göra serier om viktiga arbetarhistoriska händelser. Tidningen Flamman
                                kommer publicera en av mina serier i månaden året ut.
                                <a href="http://flamman.se/a/arbetarhistoriska-rutor-i-flamman" target="_blank" rel="noopener noreferrer"> Här </a> finns en artikel om projektet som jag valt att kalla Arbetarhistoria.
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                <Grid container
                                    spacing={0}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    className="center-content">
                                        <Grid item xs style={{paddingRight: 10}}>
                                    <img src="http://malingranroth.se/portfolio/MalinTA.jpg" alt="Artikel ur tidningen Trelleborgs Allehanda" className="image"/>
                                </Grid>
                                </Grid>
                                Artikel om mig och min bok En klump i magen i Trelleborgs Allehanda (20/5-2020).
                                <br />
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                I Skånska Dagbladet och i Norra Skåne 5/5-2020 är det med en artikel om mig och min bok.
                                <br />
                                <br />Artikeln i Skånska Dagbladet finns <a href="https://www.skd.se/2020/05/05/klump-i-magen-blev-en-debutbok/" target="_blank" rel="noopener noreferrer">här.</a>
                                <br />Artikeln i Norra Skåne finns  <a href="https://www.nsk.se/2020/05/05/klump-i-magen-blev-en-debutbok/" target="_blank" rel="noopener noreferrer">här.</a>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                Lite publicitet i Östergörland, där jag är född och uppvuxen. Här i Norrköpings tidningar, Norrköpings Magazinet och Linköpings-Posten som skriver om mig och min bok En klump i magen.
                                <br />
                                <br />Artikeln i Norrköpings Tidningar finns <a href="https://nt.se/bli-prenumerant/artikel/wjvvnn7j" target="_blank" rel="noopener noreferrer">här (enbart prenumeranter).</a>
                                <br />Artikeln i Norrköpings Magazinet  <a href="http://etidning.norrkopingsmagazinet.se/reader/ntmnom/20200506" target="_blank" rel="noopener noreferrer">här (sida 26).</a>
                                <br />Artikeln i Linköpings-Posten  <a href="http://etidning.linkopingsposten.se/reader/ntmlip/20200505" target="_blank" rel="noopener noreferrer">här (sida 30).</a>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                Jag är med i SVT´s Kulturprogrammet Sverige och pratar om min bok En klump i magen. Avsnittet sändes
                                25 april och finns tillgängligt på SVT play <a href="https://www.svtplay.se/video/26523730/sverige/sverige-sasong-23-anders-de-la-motte-erotiska-noveller-serietecknare" target="_blank" rel="noopener noreferrer">här.</a>
                                <br /><br />
                                <Grid container
                                    spacing={0}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    className="center-content">
                                        <Grid item xs>
                                            <img src="http://malingranroth.se/portfolio/svt3.jpg" alt="Kulturprogrammet Sverige" className="image"/>
                                        </Grid>
                                        <Grid item xs style={{marginLeft:5}}>
                                            <img src="http://malingranroth.se/portfolio/svt2.jpg" alt="Kulturprogrammet Sverige" className="image" />
                                        </Grid>
                                        <Grid item xs style={{marginLeft:5}}>
                                            <img src="http://malingranroth.se/portfolio/svt1.jpg" alt="Kulturprogrammet Sverige" className="image"/>
                                        </Grid>
                                </Grid>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                Jag och min serietecknarkollega Olivia Skoglund gästade Culturestream Malmös livesändning 18 april och snackade om våra böcker.
                                Både jag och Olivia debuterade med våra självbiografiska serieromaner samtidigt. I sändningen var även Malin Carlberg och Bo Mehr med.
                                <a href="https://www.youtube.com/watch?v=aDCivBdHvgs" target="_blank" rel="noopener noreferrer"> Här finns en YouTube länk till programmet.</a>
                                <br /><br />
                                <Grid container
                                    spacing={0}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    className="center-content">
                                        <Grid item xs style={{paddingRight: 10}}>
                                            <img src="http://malingranroth.se/portfolio/culturestream.jpg" alt="Culturestream Malmö" className="image" />
                                        </Grid>
                                </Grid>
                                {/*<Divider style={{marginTop: 30, marginBottom : 30}} />
                                 Det har kommit upp några recensioner av min bok En klump i magen. Länkar till dom här nedan.
                                <br />
                                <br />Recension av Simon säger <a href="https://smorkin.wordpress.com/2020/03/25/en-klump-i-magen/" target="_blank" rel="noopener noreferrer">här.</a>
                                <br />Recension av Shazam, Sveriges största blogg om tecknade serier <a href="http://www.shazam.se/2020/03/29/en-klump-i-magen/" target="_blank" rel="noopener noreferrer">här.</a>
                                <br />Recension av instagramkontot Bokfylla <a href="https://www.instagram.com/p/B-Xl2O4pv2H/?utm_source=ig_web_copy_link" target="_blank" rel="noopener noreferrer">här.</a>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                Lokaltidningen i Malmö, numret lördag 14 mars, har med en intervju om mig och min bok En klump i magen. Artikeln gär att läsa online på deras hemsida.
                                <a href="https://e.lokaltidningen.se/#pages/265300/1/1" target="_blank" rel="noopener noreferrer"> Läs tidningen här.</a>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                I nummer 1 2020 av tidningen OTTAR är det med en intervju med mig om min kommande bok, En klump i magen, samt ett kortare utdrag ur boken.
                                <br /><br />
                                <Grid container
                                    spacing={0}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    className="center-content">
                                        <Grid item xs style={{paddingRight: 10}}>
                                            <img src="http://malingranroth.se/portfolio/ottar2.jpg" alt="Utdrag ur tidningen OTTAR" className="image" />
                                        </Grid>
                                </Grid>
                                <span className="small-text">Utdrag ur tidningen OTTAR.</span>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                I nummer 222 av Bild & Bubbla är det med en intervju med mig om min kommande bok, En klump i magen, samt en förhandstitt ur boken.
                                <br /><br />
                                <Grid container
                                    spacing={0}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    className="center-content">
                                        <Grid item xs style={{paddingRight: 10}}>
                                            <img src="http://malingranroth.se/portfolio/bb1.jpg" alt="Omslag på tidningen Bild & Bubbla" className="image" />
                                        </Grid>
                                        <Grid item xs>
                                            <img src="http://malingranroth.se/portfolio/bb2.jpg" alt="Artikel i tidningen Bild & Bubbla" className="image" />
                                        </Grid>
                                </Grid>
                                <span className="small-text">Intervju i tidningen Bild & Bubbla.</span>
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                <Grid item xs>
                                    <img src="http://malingranroth.se/portfolio/folkviljan.jpg" alt="Artikel ur tidningen Folkviljan" className="image"/>
                                </Grid>
                                I nummer 1/2020 av Folkviljan är det med en artikel om mig och min bok En klump i magen.
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                I Svensk Bokhandels nummer med vårens böcker 2020 har jag med en text om mig och mitt
                                skapande och min kommande bok, En klump i magen, Kartago förlag, under deras debutantporträtt.
                                <Divider style={{marginTop: 30, marginBottom : 30}} />
                                I nummer 27-28/2019 av tidningen Flamman är det med en intervju med mig om min kommande bok,
                                graviditetsoro och den svenska förlossningsvården. Det är också med ett kapitel ur boken.
                                <br /><br />
                                <Grid container
                                    spacing={0}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    className="center-content">
                                        <Grid item xs>
                                            <img src={flamman1} alt="Artikel ur tidningen Flamman" className="image"/>
                                        </Grid>
                                        <Grid item xs>
                                            <img src={flamman2} alt="Artikel ur tidningen Flamman" className="image"/>
                                        </Grid>
                                        <Grid item xs>
                                            <img src={flamman3} alt="Artikel ur tidningen Flamman" className="image"/>
                                        </Grid>
                                </Grid>
                                <span className="small-text">Artikel ur tidningen Flamman.</span> */}
                            </Paper>
                        </Grid>
                    </Grid>
                    </div>

                    <div className="color-line-content">
                        <Grid container
                            spacing={0}
                            direction="row"
                            justify="center"
                            alignItems="stretch">
                            <Grid item xs>
                                <Paper className="grid-item" variant="outlined">
                                    <PhotoCameraIcon style={{ fontSize: 40 }}/>
                                    <br />
                                    <div>
                                        <span className="date">Pressbilder </span>
                                        Här kan man ladda ner min pressbild högupplöst. Fri publicering med angivande
                                        av fotograf och endast i artikel/recension i samband med rubricerat evenemang.
                                        Bilden är tagen av Ylwa Moritz, Trelleborgs museum.
                                        <br />
                                        <br />
                                        <ArrowForwardIosIcon style={{ fontSize: 10, marginRight: 5 }}/><a href="https://www.dropbox.com/scl/fi/wq3drx6ocbb4slfja8j14/Malin-Granroth-portr-tt.jpg?rlkey=xespfn9l63h7mj2g4xkqdgw4b&dl=0" target="_blank" rel="noopener noreferrer" download>Pressbild</a>

                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs>
                                <Paper className="grid-item" variant="outlined">
                                    <DescriptionIcon style={{ fontSize: 40 }}/>
                                    <br />
                                    <div>
                                        <span className="date">Författarporträtt </span>
                                        Malin Granroth, född i Norrköping 1987, är serietecknare och illustratör, bosatt i Malmö.
                                        Hon är utbildad vid Serieskolan i Malmö och är en del av det feministiska seriekollektivet
                                        Dotterbolaget. Hon har medverkat i antologier så som Tecknarupproret mot kärnvapen och Nato och 
                                        blivit publicerad i tidskrifter
                                        som Ottar och Galago. Hon debuterade med sin serieromanen En klump i magen, Kartago förlag, 2020.
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                <Footer/>
            </div>
        )
    }
}
