import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const ColorButton = withStyles(theme => ({
  root: {
    backgroundColor: '#FFE393',
    '&:hover': {
      backgroundColor: '#F9D981',
    },
    borderRadius: 40,
    border: 0,
    color: '#333333',
    height: 48,
    padding: '0 30px',
    fontWeight: 600
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),    
  }  
}));

export default function CustomizedButtons(props) {
  const classes = useStyles();

  return (
      <ColorButton onClick={()=> {props.onClick()}} variant="contained" className={classes.margin}>
        {props.title}
      </ColorButton>
  )
}