export const events = [
      // {
      //   src: "http://malingranroth.se/portfolio/porten.jpg",
      //   header: "Vernissage och boksamtal",
      //   text: "Jag ställer ut på kulturlokalen Porten under oktober månad med vernissage 5 oktober. Vernissaget sker på plats och är anpassad för att värna om social distansering såväl som att kunna njuta av konsten. Det kommer vara insläpp i en dörr med ett visst antal i taget och utsläpp genom en annan dörr. Ingen föranmälan krävs, välkomna! Jag kommer även samtala om min nya bok En klump i magen tillsammans med Moa Svan, samtalet börjar 19:30 och sker enbart digitalt. Länk till streamen kommer läggas upp facebook eventet här nedan.",
      //   date: "Måndag 5 oktober",
      //   time: "Vernissage och boksignering 17:30-18:30, livestremat boksamtal 19:30-20:15",
      //   location: "Kulturlokalen Porten, Kungsgatan 84, Stockholm",
      //   link: "https://www.facebook.com/events/970424746807574"
      // },
      // {
      // src: "http://malingranroth.se/portfolio/foster.jpg",
      // header: "Utställning!",
      // text: "Jag ställer ut på kulturlokalen Porten under oktober månad. Utställningen består av utvalda sidor ur min serieroman En klump i magen samt ett par risograftryck gjorda till utställningen. ",
      // date: "Hela oktober",
      // location: "Kulturlokalen Porten, Kungsgatan 84, Stockholm"
      // }, 
      // {
      //       src: "https://portfolio-2-8zojctjv3.now.sh/static/media/ekim_omslag.94c6c71b.jpg",
      //       header: "Boksignering och samtal om förlossningsvård",
      //       text: "Save the date, mer info kommer!",
      //       date: "12 november",
      //       location: "Poeten på hörnet, Södra Förstadsgatan 65 B, Malmö"
      //       },    
      // {
      //   src: "https://portfolio-2-8zojctjv3.now.sh/static/media/ekim_omslag.94c6c71b.jpg",
      //   header: "Releasefest för min bok En klump i magen, Malmö",
      //   text: "Äntligen är det dags för boksläpp! Jag debuterar med min bok En klump i magen utgiven av Kartago förlag. Boken är en tecknad serieroman som handlar om hur det känns att vara gravid i en tid när förlossningsvården är i kris, om fördomar kring oplanerade graviditeter och om längtan efter ett jämställt föräldraskap. Allt berättat med en stor skopa humor och mycket värme. Under releasefesten kommer det att finnas möjlighet att köpa boken till förmånligt pris och få den signerad. Det kommer också vara ett kortare scensamtal och seriehögläsning. Utöver detta bjuder festen på giveaways med hemliga priser, photo booth, snacks och dryckesbiljetter till de första gästerna och så långt det räcker! Fantastiska DJ Pizza Eater spelar musik under kvällen!"
             
      //   + " Hålltider: " +
      //   "19:00 Scensamtal och seriehögläsning, " +
      //   "21:00 Vinnare av giveaways utses.",
      //   date: "Lördag 21 mars",
      //   time: "18:00 - 21:30",
      //   location: "Grand Öl & Mat, Monbijougatan 17, Malmö",
      //   link: "https://www.facebook.com/events/1451704415002337/"
      // },
      // {
      //   src: "https://gamlastansbokhandel.se/wp-content/uploads/2020/02/LisaMalin-2048x819.png",
      //   header: "Författarsamtal om BB-krisen med Malin Granroth och Lisa Bjurwald",
      //   text: "Jag och Lisa Bjurwald, journalist och författare av boken BB-krisen - sveket vid livets början, kommer prata om BB-krisen ur två olika perspektiv. Min nya serieroman En klump i magen kommer även finnas till försälning och jag kommer sitta och signera böckerna efter samtalet.",
      //   date: "Torsdg 26 mars",
      //   location: "Gamla stans bokhandel, Stora Nygatan 7, Stockholm",
      //   time: "18:00 - 19:00",
      //   link: "https://gamlastansbokhandel.se/events/samtal-med-lisa-bjurwald-malin-granroth-om-bb-krisen/?fbclid=IwAR3r_LbcIwp7SGsXqQYZxO824baT8oHj9hlFXmSrYuOrhumMFDMgyR4SGjc"
      // },
      // {
      //   src:"http://malingranroth.se/portfolio/thumbnail_Serievard.jpg",
      //   header:"Seriefest - Scensamtal med Malin Granroth och Olivia Skoglund",
      //   text:"Bokdebutanterna Olivia Skoglund (Nästan i mål! - En komiskt transition) och Malin Granroth (En klump i magen) berättar om sina nya självbiografiska serieböcker om transvård och förlossningsvård, som båda på utlämnande sätt kritiserar och belyser den svenska vårdens brister. Moderator: Natalia Batista.",
      //   date:"Lördag 25 april",
      //   location: "Stadsbiblioteket, Kung Oscars väg 11, Malmö",
      //   time:"15:00 - 15:30"
      // },
      // {
      //   src: "https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/86746731_3299025993445109_8217011785803235328_o.jpg?_nc_cat=101&_nc_ohc=Tv-LcL9nsrkAX9Dzexi&_nc_ht=scontent-arn2-1.xx&oh=4ad168747aff0f35f5c11700aef8578f&oe=5EFD5D81",
      //   header: "Stockholms internationella seriefestival",
      //   text: "Den tjugoandra upplagan av Stockholms Internationella Seriefestival äger rum den 30–31 maj. För andra gången arrangeras festivalen huvudsakligen på Konstfack, i ett gränsöverskridande samarbete mellan Konstfack och Kulturhuset Stadsteatern! Jag kommer vara på plats och signera min nya bok och delta i scensamtal.",
      //   date: "30 maj - 31 maj",
      //   location: "Konstfack, Stockholm",
      //   link: "https://www.facebook.com/events/konstfack/stockholms-internationella-seriefestival-2020-sis20/522138618448754/"
      // }
]