export const photos = [  
  {
    src: "http://malingranroth.se/portfolio/moment2022-malin-granroth.jpg",
    width: 3,
    height: 3,
    caption: "Mitt bidrag i tecknarupproret Moment22. Illustration mot Nato, publicerades i antologin Tecknarupproret mot kärnvapen och Nato, Galago förlag, 2022."
  },
  {
    src: "http://malingranroth.se/portfolio/handelsnytt-malingranroth.jpg",
    width: 4,
    height: 3,
    caption: "Enruting till fackförbundet Handels medlemstidning Handelsnytt."
  },
  {
    src: "http://malingranroth.se/portfolio/öl-etikett.jpg",
    width: 3,
    height: 3,
    caption: "Öletikett."
  },
  {
    src: "http://malingranroth.se/portfolio/il.jpg",
    width: 4,
    height: 3,
    caption: "Mitt upslag med arbetarhistoriska serier och bilder i katalogen från Illustratörcentrum 2022."
  },
  {
    src: "https://malingranroth.se/portfolio/arbetarhistoria.jpg",
    width: 4,
    height: 3,
    caption: "Del av min utställning på Östergötlands museum, 2023. Här delar ur mitt projekt Arbetarhistoria."
  },
    
    {
      src: "http://malingranroth.se/portfolio/malingranroth1.jpg",
      width: 4,
      height: 4,
      caption: "Risograftryck i tre färger."
    },
    {
      src: "http://malingranroth.se/portfolio/enklumpimagen_jobb.png",
      width: 3,
      height: 4,
      caption: "Bild ur min serieroman En klump i magen."
    },
    {
      src: "http://malingranroth.se/portfolio/omslag_flamman.jpg",
      width: 3,
      height: 4,
      caption: "Omslagsbild till tidningen Flamman."
    },
    
    {
      src: "http://malingranroth.se/portfolio/omslag3d.jpg",
      width: 3,
      height: 4,
      caption: "Omslaget på min bok En klump i magen."
    },
    
    {
      src: "https://malingranroth.se/portfolio/skorpansmulan.JPG",
      width: 3,
      height: 3,
      caption: "Barnbok i pixiformat som kan läsas från två håll. Skapad tillsammans med författaren Linnea Lundborg."
    },
    {
      src: "https://malingranroth.se/portfolio/väggmålning.jpg",
      width: 4,
      height: 3,
      caption: "Väggmålning och utskrivna bilder från barnboken Skorpan & Smulan, en bok gjord av mig och författaren Linnea Lundborg. Här som utställning på Östergötlands museum 2023."
    },
    {
      src: "http://malingranroth.se/portfolio/folk.jpg",
      width: 3,
      height: 4,
      caption: "Serie om coronapandemin, publicerades i antologin Serienördens coronabok, Doob förlag, 2021."
    },
    {
      src: "http://malingranroth.se/portfolio/NKS-malingranroth.jpg",
      width: 3,
      height: 4,
      caption: "Serie om skandelerna kring NKS."
    },
    {
      src: "http://malingranroth.se/portfolio/fuck_kort.jpg",
      width: 2,
      height: 2,
      caption: "Kollage."
    },
    {
      src: "http://malingranroth.se/portfolio/enklumpimagen_sliten.png",
      width: 4,
      height: 3,
      caption: "Bild ur min serieroman En klump i magen."
    },
    {
      src: 'http://malingranroth.se/portfolio/affisch_djur.jpg',
      width: 3,
      height: 4,
      caption: "Kollage."
    },    
    // {
    //   src: "http://malingranroth.se/portfolio/vision.jpg",
    //   width: 4,
    //   height: 3,
    //   caption: "Mittenuppslag i Visions medlemstidning."
    // },
    // {
    //   src: "http://malingranroth.se/portfolio/affisch_malmotxt.jpg",
    //   width: 3,
    //   height: 4,
    //   caption: "Affisch till MALMÖTXT."
    // },
    // {
    //   src: "http://malingranroth.se/portfolio/kollage_rott.jpg",
    //   width: 3,
    //   height: 4,
    //   caption: "Kollagebilder till en artikel i tidningen Rött."
    // },
    {
      src: "http://malingranroth.se/portfolio/mana_omslag.jpg",
      width: 3,
      height: 4,
      caption: "Omslag till tidskriften Mana."
    },
    {
      src: "http://malingranroth.se/portfolio/manapol_upplag_bradspel.jpg",
      width: 6,
      height: 4,
      caption: "Brädspel i tidskriften Mana, spelbräde och spelkort illustrerade av mig."
    },
    // {
    //   src: "http://malingranroth.se/portfolio/borgare_affisch.jpg",
    //   width: 3,
    //   height: 4,
    //   description: "Test"
    // },
    {
      src: "http://malingranroth.se/portfolio/affisch_illustation.jpg",
      width: 1,
      height: 1,
      caption: "Detalj ur en affisch."
    },    
    {
      src: "http://malingranroth.se/portfolio/enklumpimagen_serie.png",
      width: 3,
      height: 4,
      caption: "Seriesida ur min serieroman En klump i magen."
    },    
    // {
    //   src: "http://malingranroth.se/portfolio/affisch_issues.jpg",
    //   width: 3,
    //   height: 4,
    //   description: "Test"
    // },    
    
    {
      src: "http://malingranroth.se/portfolio/enklumpimagen_selfie.png",
      width: 3,
      height: 4,
      caption: "Bild ur min serieroman En klump i magen."
    }, 
    // {
    //   src: "http://malingranroth.se/portfolio/horoskop1_mana.jpg",
    //   width: 4,
    //   height: 3,
    //   caption: "Antirasistiskt horoskp i tidskriften Mana, illustationer gjorda av mig."
    // },
    // {
    //   src: "http://malingranroth.se/portfolio/horoskop2_mana.jpg",
    //   width: 4,
    //   height: 3,
    //   caption: "Antirasistiskt horoskp i tidskriften Mana, illustationer gjorda av mig."
    // },
    // {
    //   src: "http://malingranroth.se/portfolio/konfetti_affisch.jpg",
    //   width: 3,
    //   height: 4,
    //   caption: "Akvarell."
    // },
    // {
    //   src: "http://malingranroth.se/portfolio/linotryck.jpg",
    //   width: 3,
    //   height: 4,
    //   caption: "Linoliumtryck."
    // },
    // {
    //   src: "http://malingranroth.se/portfolio/moa.jpg",
    //   width: 4,
    //   height: 2,
    //   caption: "Illustation av Moa Martinson till Arbetarlitteratur."
    // },    
  ];
  