import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import TextField from '@material-ui/core/TextField'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { deepPurple } from '@material-ui/core/colors'
import axios from 'axios'
import Footer from './footer'
import CustomButton from './CustomButton'
import LoadingBar from './LoadingBar'
import pressbild from './images/pressbild3.jpg'
import './sharedcss.css'
import './kontakt.css'

const theme = createMuiTheme({
    palette: {
      primary: deepPurple,
    }
  })

export default class Kontakt extends Component{
    constructor(props){
        super(props)
        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
            isLoading: false,
            formMessage: ""
        }

        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.subjectChange = this.subjectChange.bind(this);
        this.messageChange = this.messageChange.bind(this);
        this.btnClick = this.btnClick.bind(this);
    }

    nameChange(event){
        this.setState({ name: event.target.value })
        this.setState({formMessage: ""})
    }

    emailChange(event){
        this.setState({ email: event.target.value })
        this.setState({formMessage: ""})
    }

    subjectChange(event){
        this.setState({ subject: event.target.value })
        this.setState({formMessage: ""})
    }

    messageChange(event){
        this.setState({ message: event.target.value })
        this.setState({formMessage: ""})
    }

    btnClick(e){   
        if(!this.state.name || !this.state.email || !this.state.subject || !this.state.message){
            this.setState({formMessage: "Fyll i alla fälten för att kunna skicka meddelande."})
            return;
        }

        this.setState({formMessage: ""})  
        this.setState({isLoading: true})
            
        axios({
            method: "POST", 
            url: '/api/send', 
            headers: {
                'Access-Control-Allow-Origin': '*',
              },
            data: {
                name: this.state.name,   
                email: this.state.email,  
                subject: this.state.subject,
                message: this.state.message
            }
        }).then((response)=>{
            if (response.data === 'success'){     
                this.resetForm()
                this.setState({formMessage: "Meddelandet har skickats!"})
            }
            else{ 
                console.log(response)
                this.setState({formMessage: "Tekniskt fel! Maila mig istället på info@malingranroth.se"})
            }            
        }).catch(() =>{
            this.setState({formMessage: "Tekniskt fel! Maila mig istället på info@malingranroth.se"})
        }).finally(() => {
            this.setState({isLoading: false})
        })           
    } 

    resetForm(){
        this.setState({
            name: "",
            email: "",
            subject: "",
            message: ""
        })
    }

    render(){
        return (    
            <div>
                <div className="main-content">
                    <Grid container 
                        spacing={3} 
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        className="center-content">
                        <Grid item xs>
                            <Paper className="grid-item">
                                <img src="https://malingranroth.se/portfolio/press-ekim.jpg" alt="Pressbild på Malin Granroth" className="image"/>
                                <span className="small-text">Foto: Östergötlands museum, 2023.</span>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className="grid-item">    
                                <div  style={{paddingTop: 0}}>
                                    Jag är född 1987 i Norrköping och är sen 2011 boende och verksam i Malmö. Jag är sen 2013 medlem i det feministiska 
                                    seriekollektivet Dotterbolaget och är en del av <a href="https://www.femart.se/" target="_blank" rel="noopener noreferrer">Femart</a>. 
                                    Jag är även medlem i Svenska Tecknare och <a href="https://illustratorcentrum.se/kreator/malin_granroth/" target="_blank" rel="noopener noreferrer">Illustratörcentrum</a>. 
                                    Jag har utbildat mig på bl.a. HDK i Göteborg och gått två år på Kvarnby Serieskola i Malmö.   
                                </div>                           
                                <br /><br />
                                <div>
                                    Jag debuterade med min tecknade serieroman En klump i magen utgiven av Kartago förlag våren 2020. 2022 gjorde jag en
                                    barnbok i pixiformat tillsammans med författaren Linnea Lundborg. Boken var en del av Dotterbolagets projekt 
                                    Dotterbolaget &lt;3 Asylgruppen och skickades ut till projekts prenumeranter under december 2022.   
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

                <div className="color-line-content">
                    <Grid container 
                        spacing={0} 
                        direction="row"
                        justify="center"
                        alignItems="stretch">
                        <Grid item xs>
                            <Paper className="grid-item" variant="outlined">
                                <BorderColorIcon style={{ fontSize: 40 }}/>
                                <br />
                                Jag driver sen 2017 min egna firma där jag löpande tar emot illustations- och serieuppdrag.
                                <br />
                                <br />
                                Tidigare uppdrag har varit bl.a. omslag, redaktionell illustration, muralmålningar, 
                                illustration till tryck på merchandise samt serier på specifika teman och innehåll. 
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className="grid-item" variant="outlined">
                                <ImportContactsIcon style={{ fontSize: 45 }}/>
                                <br />
                                Jag har jobbat med tidningar så som Historiskan, Galago, Ottar, Flamman,  
                                Tidningen Vision och Handelsnytt.
                                <br />
                                <br/>
                                Jag har haft utställningar på bl.a. Trelleborgs museum, Östergötlands museum och Malmö konsthall.                                   
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className="grid-item" variant="outlined">
                                <ColorLensIcon style={{ fontSize: 45 }}/>
                                <br />
                                <div>
                                Jag jobbar även deltid som bildpedagog inom kulturskolan där jag undervisar i serieteckning och bild & form. 
                                Jag arbetar även med olika projekt kopplat till barn och ungas inflytande och delaktighet och är 
                                utbildad barnrättsstrateg.
                                <br />
                                <br />
                                Utöver det håller jag workshops i serieteckning och konst för alla åldrar.
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

                <div className="main-content bottom" id="contact-form"> 
                    <ThemeProvider theme={theme}>             
                        <Paper className="grid-item center-content">
                            <form noValidate autoComplete="off" id="contact-form">
                                <p style={{margin: 8}}>Kontaka mig via formuläret här nedan eller skicka ett mail till info@malingranroth.se</p>
                        
                                <div>
                                    <TextField
                                        value={this.state.name}
                                        onChange={this.nameChange}
                                        id="name"
                                        label="Namn"                     
                                        variant="outlined"
                                        required
                                        style={{ margin: 8 }}
                                        />

                                    <TextField
                                        value={this.state.email}
                                        onChange={this.emailChange}
                                        id="email"
                                        label="Epost"                    
                                        variant="outlined"
                                        required
                                        style={{ margin: 8 }}
                                        />

                                    <TextField
                                        value={this.state.subject}
                                        onChange={this.subjectChange}
                                        id="subject"
                                        label="Ämne"                     
                                        variant="outlined"
                                        required
                                        style={{ margin: 8 }}
                                        />
                                </div>
                                <div style={{paddingRight:15}}>
                                    <TextField
                                        value={this.state.message}
                                        onChange={this.messageChange}
                                        id="message"
                                        label="Meddelande"    
                                        multiline
                                        rows="10"   
                                        fullWidth
                                        variant="outlined"
                                        required
                                        style={{ margin: 8 }}
                                        />
                                </div>
                                <div className="form-bottom">
                                    <p>{this.state.formMessage}</p>
                                    {this.state.isLoading === true && <LoadingBar />}   
                                    <CustomButton onClick={this.btnClick} title="Skicka meddelande"/>
                                </div>                                                     
                            </form>
                        </Paper>
                    </ThemeProvider>      
                </div>
                <Footer/>
            </div>  
        )
    }
}